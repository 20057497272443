* {
	box-sizing: border-box;
}

html {
	&[data-bs-theme="dark"] {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

body {
	height: 100vh;
	height: 100svh;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}
