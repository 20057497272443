@import "variables";

//-----------------------------------------------
// Font icon (for before/after content)
//-----------------------------------------------
@mixin font-icon($content, $font-size) {
	font-family: $font-family-icon-light;
	content: $content;
	font-size: $font-size;
}

//-----------------------------------------------
// Placeholder
//-----------------------------------------------
@mixin placeholder {
	&::-webkit-input-placeholder {
		@content;
	}
	&:-moz-placeholder {
		opacity: 1;
		@content;
	}
	&::-moz-placeholder {
		opacity: 1;
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
}

//-----------------------------------------------
// Scrollbar
//-----------------------------------------------
// Custom styled scrollbar
@mixin scrollbar($bg, $thumb) {
	scrollbar-color: $thumb $bg;
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		width: 3px;
		height: 3px;
		background-color: $bg;
	}

	&::-webkit-scrollbar-thumb {
		background: $thumb;
		border-radius: $border-radius;
	}
}

// Hide scrollbar
@mixin hide-scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;

	&::-webkit-scrollbar {
		display: none;
	}
}

//---------------------------------------------------
// Color mode switch for theme variables.
// This uses Bootstrap's color mode mixin to
// generate CSS variables for each color mode.
//---------------------------------------------------
@mixin generate-custom-properties($map) {
	@include color-mode(light) {
		@each $property, $value in $map {
			--#{$prefix}#{$property}: #{nth($value, 1)};
		}
	}

	@include color-mode(dark) {
		@each $property, $value in $map {
			--#{$prefix}#{$property}: #{nth($value, 2)};
		}
	}
}

//---------------------------------------------------
// Avatar (with mask)
//---------------------------------------------------
@mixin squircle-mask() {
	-webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjAwJyBoZWlnaHQ9JzIwMCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNMTAwIDBDMjAgMCAwIDIwIDAgMTAwczIwIDEwMCAxMDAgMTAwIDEwMC0yMCAxMDAtMTAwUzE4MCAwIDEwMCAwWicvPjwvc3ZnPg==);
	mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjAwJyBoZWlnaHQ9JzIwMCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNMTAwIDBDMjAgMCAwIDIwIDAgMTAwczIwIDEwMCAxMDAgMTAwIDEwMC0yMCAxMDAtMTAwUzE4MCAwIDEwMCAwWicvPjwvc3ZnPg==);
}

@mixin squircle($size, $font-size: $font-size-base, $icon-size: $font-size-2xl) {
	--squircle-size: #{$size};

	width: var(--squircle-size);
	height: var(--squircle-size);
	-webkit-mask-size: var(--squircle-size) var(--squircle-size);
	mask-size: var(--squircle-size) var(--squircle-size);
	font-size: $font-size;

	&.ph,
	.ph {
		font-size: $icon-size;
	}
}
