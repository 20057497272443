//---------------------------------------
// Analytics
//---------------------------------------
#chart-sessions-country {
	&:before {
		width: calc(100% - 3.3rem);
		height: calc(100% - 3.2rem);
		left: 3.35rem;
		top: 1rem;
	}
}

#list-sessions-by-channels {
	white-space: nowrap;
	padding-bottom: 1rem;

	@include media-breakpoint-up(lg) {
		display: grid;
		grid-template-columns: repeat(5, 8.5rem);
		gap: 1.5rem 0;
	}

	@include media-breakpoint-down(lg) {
		display: flex;
	}

	& > div {
		text-align: center;
		flex-shrink: 0;
		min-width: 8rem;
	}
}

//---------------------------------------
// Photos
//---------------------------------------
.photos-check {
	&.form-check-input[type="checkbox"] {
		border-color: $white;

		&:focus {
			box-shadow: none;
		}

		&:checked {
			--bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
		}
	}
}

.photos-item {
	&:hover {
		.photos-check {
			display: block !important;
		}
	}
}

.photos-highlight {
	&::before {
		content: "";
		left: 0;
		top: 0;
		border-radius: 0.355rem;
		position: absolute;
		width: 100%;
		height: 50%;
		background: linear-gradient(180deg, rgba($black, 0.5) 0%, rgba($black, 0) 100%);
	}
}

//---------------------------------------
// Todo lists
//---------------------------------------
.todo-checkbox {
	& ~ label {
		transition: color 300ms;
	}
	&:checked {
		& ~ * {
			text-decoration: line-through;
			opacity: 0.75;
		}
	}
}

//---------------------------------------
// User
//---------------------------------------
.profile-avatar {
	@include media-breakpoint-down(lg) {
		@include squircle-mask();
		@include squircle(100%);
	}
}
