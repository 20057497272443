.card {
	.card {
		&::before {
			content: "";
			inset: 0;
			position: absolute;
			z-index: -1;
			backdrop-filter: blur(0.75rem);
			background-color: var(--#{$prefix}component-bg);
			border-radius: $border-radius;
			border: 1px solid var(--#{$prefix}component-border-color);
		}
	}
}

.card-title {
	font-weight: $font-weight-medium;
	margin-top: -0.075rem;

	&:not([class*="fs-"]) {
		font-size: $font-size-base;
	}
}

.card-subtitle {
	font-weight: normal;
	color: var(--#{$prefix}body-color);
	line-height: 1.5;
	font-size: $font-size-md;
}

.card-header {
	.card-header-pills {
		gap: 1px;
		margin-left: -0.5rem;
	}
}

.card-hover {
	transition:
		background-color 200,
		border-color 200;

	&:hover {
		background-color: var(--#{$prefix}theme-50);
		border-color: var(--#{$prefix}theme-300);
	}
}

.card-banner {
	border-radius: 0.3rem;
	margin: 0.35rem 0.35rem 0.25rem 0.35rem;
	padding: 1.75rem;
}
