.sidebar {
	--sidebar-link-emphasis-color: #cfe6ff;
	--sidebar-link-body-secondary-color: #3678be;

	position: fixed !important;
	left: 0;
	top: 0;
	width: $sidebar-width;
	height: 100%;
	z-index: $sidebar-z-index;
	transition:
		transform 300ms,
		width 300ms,
		opacity 300ms,
		background-color 300ms;
	will-change: transform, width;
	background: var(--#{$prefix}sidebar-bg);
	box-shadow: var(--#{$prefix}sidebar-box-shadow);

	&:before {
		content: "";
		width: $sidebar-min-bar-width;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		background-color: rgba($black, 0.1);
	}

	@include media-breakpoint-down(xl) {
		transform: translate3d(-100%, 0, 0);

		&:not(.toggled) {
			box-shadow: none;
		}

		&.toggled {
			transform: translate3d(0, 0, 0);
		}
	}

	&[data-simplebar] {
		.simplebar-scrollbar:before {
			background-color: var(--#{$prefix}sidebar-scrollbar-bg);
		}
	}
}

.sidebar-min {
	.sidebar {
		[data-simplebar] {
			.simplebar-scrollbar {
				display: none !important;
			}
		}

		@include media-breakpoint-up(xl) {
			width: $sidebar-min-bar-width;

			&:hover {
				width: $sidebar-width;
			}

			&:not(:hover) {
				.menu-title {
					&:before {
						opacity: 1;
					}
				}

				.menu {
					& > li {
						& > a {
							& > span {
								opacity: 0;
							}
						}
					}
				}

				.menu-sub {
					& > a {
						&:after {
							opacity: 0;
						}
					}

					& > ul {
						display: none !important;
					}
				}
			}
		}
	}
}

// Menu
.menu {
	flex: 1 1 auto;
	height: 100%;
	list-style: none;
	padding: 0;
	margin: 0;

	& > li {
		& > a {
			color: var(--#{$prefix}sidebar-link-color);
			display: flex;
			align-items: center;
			padding: $nav-link-padding-y $nav-link-padding-x;
			transition:
				color 300ms,
				background-color 300ms,
				border-color 300ms;
			position: relative;
			border-radius: $border-radius-pill;

			& > .ph {
				font-size: 1.15rem;
				margin: 0 2.25rem 0 -0.1rem;
				flex-shrink: 0;
			}

			& > span {
				opacity: 1;
				transition: opacity 300ms;
			}
		}

		&:not(.menu-title):hover > a,
		&.active > a {
			color: var(--sidebar-link-emphasis-color);
		}

		&.active {
			& > ul {
				display: block;
			}
		}

		&.opened {
			& > a {
				&:after {
					transform: rotate(90deg);
				}

				&:before {
					opacity: 1;
					transform: scale(1);
				}
			}

			& > ul {
				margin-bottom: 0.5rem;
			}
		}
	}
}

.menu > li,
.menu-sub > ul > li {
	& > a {
		white-space: nowrap;
	}
}

.menu-title {
	font-size: $font-size-sm;
	white-space: nowrap;
	line-height: 100%;
	position: relative;
	margin-bottom: 0.5rem;
	padding-left: 4rem;
	color: var(--#{$prefix}sidebar-title-color);
	transition: color 300ms;

	&:not(:first-child) {
		margin-top: 1.5rem;
	}

	@include media-breakpoint-up(xl) {
		&:before {
			content: "...";
			position: absolute;
			left: 0.7rem;
			bottom: 0.1rem;
			font-size: $content-padding;
			line-height: 100%;
			opacity: 0;
			transition: opacity 300ms;
		}
	}
}

.menu-sub {
	& > a {
		position: relative;

		&:after {
			opacity: 1;
			@include font-icon("\e13a", $font-size-xs);
			position: absolute;
			right: 0.65rem;
			top: 0.6rem;
			transition:
				transform 300ms,
				opacity 300ms;
			pointer-events: none;
			font-weight: $font-weight-bold;
			-webkit-text-fill-color: currentColor;
		}
	}

	& > ul {
		padding: 0 0.425rem;
		list-style: none;
		overflow: hidden;
		display: none;
		margin: 0 -0.5rem 0 3rem;
		will-change: height, opacity;
		transition:
			height 300ms,
			opacity 400ms;
		//background-color: #26455a4d;

		& > li {
			& > a {
				font-size: $font-size-sm;
				display: block;
				padding: 0.25rem 0.65rem;
				color: var(--#{$prefix}sidebar-link-color);
				transition: background-color 300ms;

				&:hover,
				&.active {
					color: var(--sidebar-link-emphasis-color);
				}
			}
		}
	}
}

// Sidebar list
// Secondary sidebar in 2 and 3 column layouts
// e.g contacts, messages, file manager, etc
#sidebar-secondary {
	@include media-breakpoint-down(xl) {
		will-change: transform, opacity;
		position: fixed;
		left: 0;
		top: 0;
		height: 100%;
		z-index: $sidebar-z-index + 2;
		transform: translate3d(-100%, 0, 0);
		transition:
			transform 300ms,
			opacity 300ms;
		opacity: 0;
		background: var(--#{$prefix}body-bg);

		&.toggled {
			transform: translate3d(0, 0, 0);
			opacity: 1;
			box-shadow: 0 0 1rem var(--#{$prefix}component-shadow-color);
		}
	}
}

// Logo
.logo {
	display: flex;
	align-items: center;
	height: 3.5rem;
	flex-shrink: 0;
	padding: 0 0.9rem;
	color: var(--sidebar-link-emphasis-color);
	font-size: $font-size-lg;
	white-space: nowrap;
	gap: 1.85rem;
	white-space: nowrap;

	& > svg {
		flex-shrink: 0;
	}
}
