.breadcrumb-item {
	& > a {
		color: var(--#{$prefix}secondary-color);

		&:hover {
			color: var(--#{$prefix}body-color);
		}
	}

	& + .breadcrumb-item {
		display: inline-flex;
		align-items: center;

		&:before {
			font-family: $font-family-icon-light;
			font-size: 0.6rem;
			line-height: 1;
		}
	}
}
