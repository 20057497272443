$tooltip-bg-color: $tooltip-bg;
$tooltip-font-family: $font-family-sans-serif;
$tooltip-radius: $tooltip-border-radius;
$tooltip-padding: $tooltip-padding-y $tooltip-padding-x;
$tooltip-font-size: $tooltip-font-size;

@import "jsvectormap/src/scss/jsvectormap.scss";

.jvm-tooltip {
	color: $tooltip-color;
	backdrop-filter: blur(0.5rem);
	z-index: 10;
	pointer-events: none;
	box-shadow: 0 0 0 0.2rem var(--#{$prefix}tooltips-border-color);
}
