.calendar {
	--fc-border-color: var(--#{$prefix}theme-200);
	--fc-button-bg-color: transparent;
	--fc-button-hover-bg-color: var(--#{$prefix}theme-200);
	--fc-button-active-bg-color: var(--#{$prefix}theme-200);
	--fc-button-text-color: var(--#{$prefix}emphasis-color);
	--fc-today-bg-color: transparent;
	--fc-event-bg-color: $blue;
	--fc-event-border-color: transparent;
	--fc-neutral-bg-color: transparent;
	--fc-event-text-color: var(--#{$prefix}text-color);

	&.fc {
		width: 100%;

		a {
			color: var(--#{$prefix}body-color);
		}

		table {
			tr {
				&:last-child {
					td {
						border-bottom: 0;
					}
				}
			}

			td,
			th {
				&:last-child {
					border-right: 0;
				}
			}
		}

		.fc-toolbar.fc-header-toolbar {
			background-color: transparent;
			height: 3rem;
			padding: 0 0.5rem;
			margin-bottom: 0.1rem;
		}

		.fc-toolbar-title {
			color: var(--#{$prefix}emphasis-color);
			margin: 0;
			font-size: $font-size-base;
			font-weight: $font-weight-normal;
		}

		.fc-button {
			border-width: 0;
			border-radius: $border-radius;
			height: 1.85rem;
			min-width: 1.85rem;
			padding: 0;
			margin: 0 0.2rem;
			display: inline-flex;
			align-items: center;
			justify-content: center;

			&:disabled {
				opacity: 1;
				color: var(--#{$prefix}secondary-text-emphasis);
			}

			&:focus {
				box-shadow: none !important;
			}

			.fc-icon {
				font-size: 1.15rem;
			}

			&.fc-next-button {
				margin-right: 1rem;
			}
		}

		.fc-scrollgrid {
			border: 0;
		}

		.fc-view-harness {
			border-radius: $border-radius;

			@extend .card;
		}

		.fc-col-header-cell {
			padding: 0.5rem 0;

			a {
				font-weight: $font-weight-normal;
				font-size: $font-size-sm;
			}
		}

		.fc-daygrid-day-top {
			justify-content: center;
		}

		.fc-day-other .fc-daygrid-day-top {
			opacity: 0.5;
		}

		.fc-daygrid-day-number {
			margin: 0.5rem 0;
		}

		.fc-day-today {
			.fc-daygrid-day-number {
				background-color: $blue;
				color: var(--#{$prefix}invert-color);
				font-weight: $font-weight-normal;
				width: 2rem;
				height: 2rem;
				border-radius: $border-radius;
				display: grid;
				place-content: center;
			}
		}

		.fc-daygrid-day-events {
			padding: 0.25rem 0.25rem 0.35rem;
		}

		.fc-event {
			background-color: var(--#{$prefix}theme-200);
		}

		.fc-h-event,
		.fc-v-event {
			font-size: $font-size-sm;
			padding: 0.1rem 0.3rem;
			border-radius: $border-radius-sm;
		}

		.fc-scroller {
			@include scrollbar(transparent, var(--#{$prefix}scrollbar-bg));
		}

		.fc-daygrid-event-dot {
			background-color: $blue;
			border-width: 0;
			width: 0.25rem;
			height: 0.25rem;
			flex-shrink: 0;

			@include media-breakpoint-down(lg) {
				display: none;
			}
		}

		.fc-daygrid-dot-event {
			padding: 0.2rem 0.3rem;
			font-weight: normal;

			&:hover {
				background-color: var(--#{$prefix}theme-200);
			}

			&,
			.fc-event-title {
				color: var(--#{$prefix}emphasis-color);
			}
		}

		.fc-event-title {
			font-weight: $font-weight-medium;
			color: var(--#{$prefix}emphasis-color);
		}

		.fc-event-time {
			font-size: $font-size-xs;
		}

		.fc-day {
			&:hover {
				background: var(--#{$prefix}theme-50);
			}
		}

		.fc-timegrid-axis,
		.fc-timegrid-slot {
			font-size: $font-size-sm;
		}

		.fc-timegrid-event-harness-inset .fc-timegrid-event,
		.fc-timegrid-event.fc-event-mirror,
		.fc-timegrid-more-link {
			box-shadow: none;
		}

		@include media-breakpoint-down(sm) {
			.fc-today-button,
			.fc-timeGridDay-button {
				display: none;
			}
		}
	}
}
