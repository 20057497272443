.popover-header,
.popover-body {
	overflow: hidden;
	border-radius: $border-radius;
}

.popover-header {
	--bs-popover-border-width: 0;

	padding-bottom: 0;
}
