.header {
	height: $header-height;
	z-index: $sidebar-z-index - 2;
	gap: 0.5rem;
	padding: 0 ($content-padding - 0.25rem);
	display: flex;
	align-items: center;
	background-color: $card-bg;
	border-bottom: 1px solid $card-border-color;
	flex-shrink: 0;

	@include media-breakpoint-up(xl) {
		padding-left: $sidebar-width + $content-padding;
	}
}

.sidebar-min {
	.header {
		@include media-breakpoint-up(xl) {
			padding-left: $sidebar-min-bar-width + $content-padding;
		}
	}
}

.header-menu {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	list-style: none;
	margin: 0;
	gap: 0.85rem;
	padding: 0;

	& > li {
		& > button {
			border: 0;
			background-color: transparent;
			color: var(--#{$prefix}emphasis-color);
			font-size: $font-size-2xl;
			min-width: 2.25rem;
			min-height: 2.25rem;
			border-radius: $border-radius;
			display: flex;
			align-items: center;
			justify-content: center;
			-webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjAwJyBoZWlnaHQ9JzIwMCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNMTAwIDBDMjAgMCAwIDIwIDAgMTAwczIwIDEwMCAxMDAgMTAwIDEwMC0yMCAxMDAtMTAwUzE4MCAwIDEwMCAwWicvPjwvc3ZnPg==);
			mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjAwJyBoZWlnaHQ9JzIwMCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNMTAwIDBDMjAgMCAwIDIwIDAgMTAwczIwIDEwMCAxMDAgMTAwIDEwMC0yMCAxMDAtMTAwUzE4MCAwIDEwMCAwWicvPjwvc3ZnPg==);
			@include squircle(2.25rem);

			&:hover,
			&.show {
				background: var(--#{$prefix}theme-200);
				background: var(--#{$prefix}theme-200);
			}
		}

		.dropdown-menu {
			&.header-dropdown-menu {
				left: auto;
				top: $header-height - 0.4rem;
				right: 0.5rem;
				position: fixed;
				height: calc(100% - ($header-height + 0.2rem));
				max-width: 21rem;
				width: 100%;
				display: flex;
				flex-direction: column;
				opacity: 0;
				pointer-events: none;
				transform: translate3d(0, 0, 0);
				transition:
					opacity 300ms,
					transform 300ms;
				margin-top: 0;

				&.show {
					transform: translate3d(-1rem, 0, 0);
					opacity: 1;
					pointer-events: auto;
				}

				@include media-breakpoint-down(sm) {
					max-width: calc(100% - 2rem);
				}
			}
		}
	}
}

.header-notify {
	position: relative;
	isolation: isolate;

	&:after {
		will-change: transform, border-color;
		content: "";
		position: absolute;
		width: 2.1875rem;
		height: 2.1875rem;
		border-radius: 50%;
		border: 2px solid transparent;
		z-index: 1;
		inset: 0;
		margin: auto;
		transform: scale3d(0, 0, 0);
		animation-iteration-count: infinite;
		animation-duration: 2000ms;
		backface-visibility: hidden;
		animation-name: notifyPulse;
		pointer-events: none;
	}
}

// Color mode
#color-mode-toggle {
	display: grid;
	place-items: center;
	overflow: hidden;
	position: relative;

	.ph {
		font-size: $font-size-2xl;
		position: absolute;
		transform: scale(0);
		opacity: 0;
		transition:
			transform 300ms,
			opacity 300ms;
	}
}

[data-bs-theme="light"] {
	#color-mode-toggle {
		.ph-moon {
			transform: scale(100%);
			opacity: 1;
		}
	}
}

[data-bs-theme="dark"] {
	#color-mode-toggle {
		.ph-sun {
			transform: scale(100%);
			opacity: 1;
		}
	}
}

// Top navigation
@keyframes notifyPulse {
	0% {
		transform: scale3d(0, 0, 0);
		border-color: transparent;
	}

	50% {
		border-color: rgba($red, 0.75);
	}

	to {
		transform: scale3d(1, 1, 1);
		border-color: transparent;
	}
}

// Search
.search {
	background-color: transparent;
	display: inline-flex;
	align-items: center;
	max-width: 22rem;
	width: 100%;

	input {
		border: 0;
		appearance: none;
		height: 3rem;
		width: 100%;
		font-size: $font-size-md;
		padding-top: 1px;
		background-color: transparent;
	}

	&:focus-within {
		.search-helper {
			& > i {
				&:first-child {
					transform: translateX(-100%);
					opacity: 0;
				}

				&:last-child {
					transform: translateX(0);
					opacity: 1;
				}
			}
		}
	}
}

.search-helper {
	display: grid;
	place-items: center;
	margin: 0 1rem 0 0.5rem;

	& > i {
		position: absolute;
		font-size: $font-size-xl;
		transition:
			transform 300ms,
			opacity 300ms;

		&:last-child {
			opacity: 0;
			transform: translateX(100%);
		}
	}
}

#sidebar-toggle {
	background: var(--#{$prefix}logo-fill);
	color: var(--#{$prefix}sidebar-toggle-color);
	@include squircle(2rem);
	@include squircle-mask();

	&:hover {
		opacity: 0.9;
	}
}
