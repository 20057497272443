.pagination {
	.page-link {
		display: flex;
		align-items: center;
	}

	&:not(.pagination-sm) {
		.page-link {
			min-height: 2.25rem;
		}
	}
}
