.quill {
	border-radius: $border-radius;

	.ql-snow {
		&.ql-toolbar {
			border-color: transparent;
			background-color: transparent;
			padding: 0.5rem;

			.ql-formats {
				margin-right: 0.5rem;
				display: inline-flex;
				align-items: center;
				gap: 1px;
			}

			button {
				border-radius: $border-radius;
				padding: 0;
				width: 1.75rem;
				height: 1.75rem;
				display: grid;
				place-content: center;

				&:hover {
					background-color: var(--#{$prefix}theme-50);
					color: var(--#{$prefix}emphasis-color);
				}

				&.ql-active {
					background-color: var(--#{$prefix}theme-200);
					color: var(--#{$prefix}emphasis-color);
				}
			}
		}

		&.ql-container {
			min-height: 3rem;
			border: 0;
			background-color: transparent;
		}

		.ql-editor {
			font-family: $font-family-sans-serif;
			font-size: $font-size-sm;

			&::before {
				font-size: $font-size-sm;
				color: $body-secondary-color;
				font-style: normal;
			}
		}

		.ql-tooltip {
			margin: 0;
			border-radius: 50%;
			border: 1px solid $gray-400;
			padding: 0.25rem;

			&::before {
				content: "";
				display: none;
			}

			&:not(.ql-hidden) {
				display: flex;
			}

			a.ql-action,
			a.ql-remove {
				@extend .icon;
				color: $body-secondary-color;

				&:hover {
					color: $body-emphasis-color;
				}
			}

			a.ql-remove {
				font-size: $font-size-lg;

				&::before {
					content: "\e3c9";
					margin: 0;
				}
			}

			a.ql-action {
				margin-right: 0.25rem;
				font-size: $font-size-lg;

				&:after {
					content: "\e92d";
					margin: 0;
					padding: 0;
				}
			}

			&[data-mode="link"]:before {
				display: none;
			}

			&.ql-editing {
				input[type="text"] {
					width: 10rem;
					border: 0;
					background-color: transparent;
					padding: 0 0.5rem;
					font-family: $font-family-sans-serif;
					color: $body-color;
				}

				a.ql-action {
					font-size: $font-size-lg;

					&:after {
						content: "\e92d";
					}
				}
			}

			.ql-preview {
				margin-right: 0.5rem;
				font-size: $font-size-sm;
				width: 8rem;
				color: $link-color;
			}
		}
	}
}
