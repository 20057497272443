.alert-dismissible {
	.btn-close {
		background-image: none;
		color: currentColor;
		display: grid;
		place-content: center;
		margin-top: 1px;

		&::before {
			@include font-icon("\E4F8", 1.25rem);
		}
	}
}

.alert-link {
	text-decoration: underline;
	text-underline-offset: 0.25rem;
	text-decoration-thickness: 1px;
	text-decoration-style: dotted;
}
