// Icons
.icon {
	min-width: 2rem;
	height: 2rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: $border-radius;
	display: inline-flex;
	transition:
		color 300ms,
		background-color 300ms,
		box-shadow 300ms;
	color: var(--#{$prefix}body-color);

	&.ph,
	& > .ph {
		&:not([class*="fs-"]) {
			font-size: 1.15rem;
		}
	}

	&:hover,
	&.show {
		background: var(--#{$prefix}theme-200);
		color: var(--#{$prefix}emphasis-color);
	}
}

// Avatar
.avatar {
	display: grid;
	place-content: center;
	font-weight: $font-weight-medium;
	text-transform: uppercase;
	flex-shrink: 0;
	@include squircle-mask();

	&:not(.avatar-md):not(.avatar-sm):not(.avatar-xs) {
		@include squircle(2.25rem, $font-size-base);
	}

	&.avatar-md {
		@include squircle(2rem, $font-size-md, $font-size-xl);
	}

	&.avatar-sm {
		@include squircle(1.85rem, $font-size-sm, $font-size-lg);
	}

	&.avatar-xs {
		@include squircle(1.35rem, $font-size-xs, $font-size-md);
	}
}

// Links
.link-bordered {
	border: 1px solid var(--#{$prefix}theme-300);
	border-radius: $border-radius;
	padding: 0 0.65rem;
	height: 1.875rem;
	font-size: $font-size-sm;
	color: var(--#{$prefix}body-color);
	display: flex;
	align-items: center;
	transition: background-color 300ms;
	line-height: 1;

	&:hover {
		border-color: var(--#{$prefix}theme-300);
		background-color: var(--#{$prefix}theme-50);
	}

	& > .ph {
		font-size: 1rem;
	}
}

.link-body,
.link-body-secondary {
	transition: color 300ms;
}

.link-body {
	color: $body-color;

	&:hover {
		color: $body-emphasis-color;
	}
}

.link-body-secondary {
	color: $body-secondary-color;

	&:hover {
		color: $body-color;
	}
}

// Masonry layout
.masonry {
	column-count: 3;
	gap: 1rem;

	@include media-breakpoint-down(xxl) {
		column-count: 2;
	}

	@include media-breakpoint-down(lg) {
		column-count: 1;
	}

	.card {
		display: inline-block;
		width: 100%;
		margin-bottom: 1rem;
	}
}

// Custom scrollbar
.scrollbar {
	scrollbar-gutter: $scrollbar-width;
	scrollbar-color: #b8c4ce #f8fafc;
	scrollbar-width: thin;
	overflow: auto;

	::-webkit-scrollbar {
		width: $scrollbar-width;
	}

	::-webkit-scrollbar-track {
		background: transparent;
	}

	::-webkit-scrollbar-thumb {
		background: #b8c4ce;
		border-radius: $border-radius;
		border: 1px solid $body-bg;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}

// Backdrop
.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	transition: opacity 500ms;
	z-index: $sidebar-z-index - 1;
	backdrop-filter: blur(0.25rem);
	background-color: $modal-backdrop-bg;
}

// Action
.bg-highlight {
	background-color: var(--#{$prefix}component-accent-bg);
}

.bg-active {
	background: var(--#{$prefix}theme-200);
}

.bg-hover {
	&:hover {
		background-color: var(--#{$prefix}component-accent-bg);
	}
}

// Page loader
#page-loader {
	position: fixed;
	inset: 0;
	z-index: 100;
	background-color: var(--#{$prefix}body-bg);
	overflow: hidden;
	display: flex;
	align-items: start;
	font-size: 0.35rem;
	transition: opacity 300ms;

	.placeholder {
		min-height: 0;
		height: 0.3rem;
		background-color: #5691ba;
		flex-shrink: 0;
		border-radius: $border-radius-lg;
	}

	.spinner-border {
		color: $body-secondary-color;
	}
}

.sidebar-min {
	.placeholder-logo {
		display: none;
	}
}

// Radial Chart
.radial-chart {
	overflow: visible;
	padding-top: 0.15rem;

	path {
		fill: transparent;
		transform: rotate(90deg);
		transform-origin: center center;
		stroke: currentColor;

		&:nth-child(1) {
			stroke-width: 0.5;
			opacity: 0.25;
		}

		&:nth-child(2) {
			stroke-width: 1.5;
			stroke-dashoffset: 0;
		}
	}

	text {
		transform-origin: center center;
		font-size: 0.45rem;
		fill: currentColor;
	}
}
