.chart {
	position: relative;
	height: 16.5rem;

	& > canvas {
		position: relative;
		z-index: 1;
	}

	&.chart-sm {
		height: 10rem;
	}

	&.chart-md {
		height: 14rem;
	}
}

.chart-bordered {
	position: relative;

	&:before {
		content: "";
		pointer-events: none;
		border: 1px solid $border-color;
		position: absolute;
		border-radius: $border-radius;
	}
}

.chart-legends {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	gap: 0 1rem;

	& > li {
		display: flex;
		align-items: center;
		font-size: $font-size-sm;

		& > i {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			margin-right: 0.5rem;
		}
	}
}

// Light and dark mode color variables for charts and maps
$chart-custom-properties: (
	// Custom chart props
	// Orange
	"chart-gradient-orange": map-get($theme-gradients, "orange") linear-gradient(45deg, rgba(196, 92, 61, 0.149) 0%, $card-bg 100%),
	"chart-shadow-orange": map-get($theme-shadows, "orange") none,
	"chart-text-orange": #ffdec3 #c36f58,
	"chart-title-orange": $white #ff734b,
	"chart-fill-orange": rgba($white, 0.5) rgba(196, 93, 61, 0.3),
	"chart-fill-scale-orange": #fffaf6 #c45c3d,
	"chart-state-bg-orange": rgba(255, 173, 122, 0.35) rgba(196, 93, 61, 0.2),
	// Lime
	"chart-gradient-lime": map-get($theme-gradients, "lime") linear-gradient(45deg, rgba(164, 178, 32, 0.129) 0%, $card-bg 100%),
	"chart-shadow-lime": map-get($theme-shadows, "lime") none,
	"chart-fill-lime": $white darken($lime, 20%),
	// white
	"chart-grid-white": rgba($white, 0.25) rgba(55, 133, 183, 0.125),
	"chart-ticks-white": $white #919844,
	// Navy
	"chart-gradient-navy": map-get($theme-gradients, "navy") linear-gradient(45deg, rgba(9, 175, 222, 0.141) 0%, $card-bg 100%),
	"chart-shadow-navy": map-get($theme-shadows, "navy") none,
	"chart-grid-navy": rgba(156, 241, 233, 0.25) rgba(38, 112, 153, 0.2),
	"chart-ticks-navy": $white #63a5bf,
	"chart-fill-navy": $white #1ccdff,
	// Green
	"chart-gradient-green": map-get($theme-gradients, "green") linear-gradient(45deg, rgba(47, 233, 215, 0.18) 0%, $card-bg 100%),
	"chart-shadow-green": map-get($theme-shadows, "green") none,
	"chart-grid-green": rgba(156, 241, 233, 0.1) rgba(38, 112, 153, 0.2),
	"chart-ticks-green": #9adfdc #3aaebb,
	"chart-fill-green": (215, 255, 253) (41, 218, 208),
	// Cyan
	"chart-gradient-cyan": map-get($theme-gradients, "cyan") linear-gradient(45deg, rgba(0, 188, 212, 0.129) 0%, $card-bg 100%),
	"chart-shadow-cyan": map-get($theme-shadows, "cyan") none,
	"chart-grid-cyan": rgba(81, 241, 201, 0.55) rgba(13, 184, 232, 0.1),
	"chart-ticks-cyan": $white #189fc5,
	"chart-fill-cyan": (255, 255, 255) (28, 205, 255),
	"chart-state-bg-cyan": rgba(73, 226, 215, 0.5) rgba(0, 188, 212, 0.149),
	"chart-title-cyan": $white #02deff,
	"chart-text-cyan": #baffef #07a6bd,
	// Blue
	"chart-gradient-blue": map-get($theme-gradients, "blue") linear-gradient(45deg, rgba(3, 129, 241, 0.188) 0%, $card-bg 100%),
	"chart-shadow-blue": map-get($theme-shadows, "blue") none,
	"chart-grid-blue": rgba(26, 169, 215, 0.5) rgba(2, 145, 255, 0.15),
	"chart-ticks-blue": #8ee5ff #3992d5,
	"chart-fill-blue": #bfefff #3198f7,
	"chart-fill-sub-blue": #09b7ea #135592,
	"chart-state-bg-blue": rgba(0, 165, 241, 0.25) rgba(0, 143, 255, 0.15),
	"chart-title-blue": #daf7ff #c3e2ff,
	"chart-text-blue": #6cc9ff #49a2e6,
	// Perano
	"chart-gradient-perano": map-get($theme-gradients, "perano") linear-gradient(45deg, rgba(53, 86, 130, 0.55) 0%, $card-bg 100%),
	"chart-shadow-perano": map-get($theme-shadows, "perano") none,
	"chart-fill-perano": rgba($white, 0.25) #45688e,
	"chart-fill-scale-perano": #e2edff #97c9fe
);
@include generate-custom-properties($chart-custom-properties);

// Available chart gradient backgrounds
// e.g: sessions-by-country, active-users, site-overview, etc.
$chart-colors: orange lime navy green cyan blue perano;

@each $color in $chart-colors {
	.chart-#{"" + $color} {
		background: var(--#{$prefix}chart-gradient-#{$color});
		box-shadow: var(--#{$prefix}chart-shadow-#{$color});

		svg {
			.jvm-region {
				fill: var(--#{$prefix}chart-fill-#{$color});

				&.jvm-region-scale {
					fill: var(--#{$prefix}chart-fill-scale-#{$color});
				}
			}
		}

		.state-item {
			background-color: var(--#{$prefix}chart-state-bg-#{$color});

			& > span {
				color: var(--#{$prefix}chart-title-#{$color});
			}

			& > small {
				color: var(--#{$prefix}chart-text-#{$color});
			}
		}
	}
}

.text-chart-green {
	color: #baffef;
}

.text-chart-blue {
	color: #64ddff;
}

.text-chart-orange {
	color: #ffdec3;
}

.bg-chart-green {
	background-color: rgba(73, 226, 215, 0.502);
}

.bg-chart-blue {
	background-color: rgba(26, 163, 209, 0.302);
}

.bg-chart-orange {
	background: var(--#{$prefix}bg-orange-dynamic);
}
