#content {
	will-change: padding;
	flex-grow: 1;
	position: relative;
	height: 100%;

	&.content-column {
		display: flex;
		flex-grow: 1;
		overflow: hidden;
	}

	&:not(.content-column) {
		overflow-y: auto;
		padding: 0 $content-padding $content-padding;

		@include media-breakpoint-up(xl) {
			padding-left: ($sidebar-width + $content-padding);
		}
	}
}

.sidebar-min {
	#content {
		@include media-breakpoint-up(xl) {
			padding-left: $sidebar-min-bar-width;
		}
	}
}

.content-body {
	flex: 1;
	overflow-y: auto;
	height: 100%;
	padding: 0 $content-padding $content-padding;
}

.content-header {
	height: 3rem;
	display: flex;
	align-items: center;
	flex-shrink: 0;
	position: sticky;
	top: 0;
	z-index: 10;
	backdrop-filter: blur(0.75rem);
	margin: 0 (-$content-padding);
	padding: 0 $content-padding;
	background-color: var(--#{$prefix}content-header-bg);
}

.content-sidebar {
	width: $sidebar-width;
	flex-shrink: 0;
	height: 100%;
}

// Switch blocks in mobile
// e.g Contacts, Email etc.
@include media-breakpoint-down(md) {
	[data-ba-block] {
		will-change: transform;
		transition: transform 300ms;
		width: 100% !important;
		position: absolute;
		top: 0;
		left: 0;
	}

	[data-ba-block="body"] {
		transform: translate3d(105%, 0, 0);
	}

	.content-body-toggled {
		[data-ba-block="list"] {
			transform: translate3d(-100%, 0, 0);
		}

		[data-ba-block="body"] {
			transform: translate3d(0, 0, 0);
		}
	}
}

// Content search
.content-search {
	display: flex;
	align-items: center;
	border: 1px solid $input-border-color;
	border-radius: $border-radius;
	padding: 0 0.75rem;

	.form-control {
		border: 0;
		background-color: transparent;

		&:focus {
			box-shadow: none;
		}
	}

	&:focus-within {
		border-color: $input-focus-border-color;
		box-shadow: $focus-ring-box-shadow;
	}
}
